<template>
  <section class="site-research">

    <div class="content content-main first-container">
      <div class="hero-banner">
        <div class="vid">
          <img src="@/assets/deemo.jpg" alt="hero">
        </div>

        <div class="text-container">
          <div class="hero_title text-uppercase">
            What am I up to?
          </div>
          <div class="hero_subtitle">
            Take a look at what I have poured my focus into below
          </div>
        </div>
      </div>
    </div>

    <div class="content content-alt-login">
      <div class="container-fluid">
        <div class="intro text-center">
          <div class="row">
            <div class="col-lg-8 mx-auto">
              <div class="heading">
                <span>Alternate login methods</span>
              </div>
              <span class="body">A current app has the issue of having too much password requirements for a mobile application. Hence I researched into what most apps are doing at the moment and tried out the coding for it.<br><br><span class="tag">React Native</span></span>
            </div>
          </div>
        </div>

        <div class="content">
          <div class="google">
              <div class="row block-texts">
                <div class="col-lg-6">
                  <span class="subtitle">Google login</span>
                </div>
              </div>

              <div class="row" animate-stagger>

                <div class="col-lg-4">
                  <div class="card-item" animate-item>
                    <div class="img">
                      <img class="w-100 img-fluid" src="@/assets/alt-login1.png" alt="login1">
                    </div>
                    <div class="texts">
                      <span>The Google login button comes from the google-login npm package for react-native. Click on the Google Sign In Button</span>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4">
                  <div class="card-item" animate-item>
                    <div class="img">
                      <img class="w-100 img-fluid" src="@/assets/alt-login2.png" alt="login2">
                    </div>
                    <div class="texts">
                      <span>A popup will appear automatically to request user's permission. Allow App permission to use your Google account as app's login</span>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4">
                  <div class="card-item" animate-item>
                    <div class="img">
                      <img class="w-100 img-fluid" src="@/assets/alt-login-google.jpg" alt="login3">
                    </div>
                    <div class="texts">
                      <span>After successfully logging in, the app will be able to get credentials from your Google account for example: profile photo, first & last name etc...</span>
                    </div>
                  </div>
                </div>

              </div>

          </div>

          <div class="facebook">

            <div class="row block-texts">
              <div class="col-lg-6">
                <span class="subtitle">Facebook login</span>
              </div>
            </div>

            <div class="row-2" animate-me>
              <div class="slides" data-slick-fb>
                <div class="slide">
                  <div class="slide-inner">
                    <div class="row align-items-center">
                      <div class="col-lg-3 order-lg-last ml-auto">
                        <div class="img">
                          <img class="w-100" src="@/assets/alt-login3.png" alt="login4">
                        </div>
                      </div>

                      <div class="col-lg-7 order-lg-first">
                        <div class="content">
                          <div class="num"><span>01</span></div>
                          <div class="text"><span>
                            Once you pressed the facebook login button, you will be brought to this screen which you can choose which method you want to login with.</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="slide">
                  <div class="slide-inner">
                    <div class="row align-items-center">
                      <div class="col-lg-3 order-lg-last ml-auto">
                        <div class="img">
                          <img class="w-100" src="@/assets/alt-login4.png" alt="login5">
                        </div>
                      </div>

                      <div class="col-lg-7 order-lg-first">
                        <div class="content">
                          <div class="num"><span>02</span></div>
                          <div class="text"><span>
                            After choosing either of the two methods in the previous screen, the current mobile app will ask you if you are ok with accessing facebook using this external app.<br><br>Press 'Continue' to proceed.</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="slide">
                  <div class="slide-inner">
                    <div class="row align-items-center">
                      <div class="col-lg-3 order-lg-last ml-auto">
                        <div class="img">
                          <img class="w-100" src="@/assets/alt-login5.png" alt="login6">
                        </div>
                      </div>

                      <div class="col-lg-7 order-lg-first">
                        <div class="content">
                          <div class="num"><span>03</span></div>
                          <div class="text"><span>
                            Upon reaching this screen. Allow the permission to use your facebook account to login to the current mobile app.</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="slide">
                  <div class="slide-inner">
                    <div class="row align-items-center">
                      <div class="col-lg-3 order-lg-last ml-auto">
                        <div class="img">
                          <img class="w-100" src="@/assets/alt-login6.png" alt="login7">
                        </div>
                      </div>

                      <div class="col-lg-7 order-lg-first">
                        <div class="content">
                          <div class="num"><span>04</span></div>
                          <div class="text"><span>
                            Tada! Now we can see that we're logged in to facebook and the button has been changed to logged out.</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <div class="slides-btn">
                <div class="row">
                  <div class="col-lg-6">
                      <div class="btns" data-slick-btns="data-slick-btns">
                        <button class="slick-prev" type="button">&#8592;</button>
                        <button class="slick-next" type="button">&#8594;</button>
                      </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div class="apple">
            <div class="row block-texts">
              <div class="col-lg-6">
                <span class="subtitle">Apple login</span>
              </div>
            </div>

            <div class="row" animate-stagger>

              <div class="col-lg-4">
                <div class="card-item" animate-item>
                  <div class="img">
                    <img class="w-100 img-fluid" src="@/assets/alt-login9.png" alt="login9">
                  </div>
                  <div class="texts">
                    <span>After pressing the apple login from react-native-apple-authentication. The screen will slide up to let users type in their apple login credentials.</span>
                  </div>
                </div>
              </div>

              <div class="col-lg-4">
                <div class="card-item" animate-item>
                  <div class="img">
                    <img class="w-100 img-fluid" src="@/assets/alt-login8.png" alt="login8">
                  </div>
                  <div class="texts">
                    <span>The users will then be sent a verification by apple to type in. This is a standard procedure for apple security.</span>
                  </div>
                </div>
              </div>

              <div class="col-lg-4">
                <div class="card-item" animate-item>
                  <div class="img">
                    <img class="w-100 img-fluid" src="@/assets/alt-login10.png" alt="login10">
                  </div>
                  <div class="texts">
                    <span>Inputting the correct verification code will then automatically log in into apple account and sync with iCloud to use for the app's login.</span>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>


    <div class="content content-dmt">
      <div class="container-fluid">
        <div class="intro text-center" animate-me>
          <div class="row">
            <div class="col-lg-8 mx-auto">
              <div class="heading">
                <span>Devil Maker Tokyo</span>
              </div>
              <span class="body">is a card battle game that I enjoyed in the past. Currently I have done adding bgm to the app and functions like: calculating type multipliers, randoming between single target or multi-target attacks, checking win/lose conditions and more...<br><br><span class="tag">React Native</span></span>
            </div>
          </div>
        </div>
      </div>

      <video controls autoplay muted animate-fade>
        <source src="@/assets/dmt.mp4" type="video/mp4">
      </video>
      
    </div>

    <div class="content content-circular-reveal">
      <div class="container-fluid">
        <div class="intro text-center" animate-me>
          <div class="row">
            <div class="col-lg-8 mx-auto">
              <div class="heading">
                <span>Circular Reveal</span>
              </div>
              <span class="body">This DIY research was inspired by my then creative teammates who shared this portfolio link with me about how cool the revealing animation was: <a target="_blank" href="http://www.francoisrisoud.com/projects">http://www.francoisrisoud.com/projects</a>.<br><br><span class="tag">HTML + Javascript</span></span>
            </div>
          </div>
        </div>

        <video controls autoplay muted loop animate-fade>
          <source src="@/assets/circular_reveal_vid.mp4" type="video/mp4">
        </video>
      </div>
    </div>

    <div class="content content-react-three">
      <div class="container-fluid">
        <div class="intro text-center" animate-me>
          <div class="row">
            <div class="col-lg-8 mx-auto">
              <div class="heading">
                <span>ThreeJS in React</span>
              </div>
              <span class="body">I made something in blender to experiment with threeJS as it has been something I've been interested for a while.<br><br><span class="tag">React JS</span><span class="tag ml-2">ThreeJS</span></span>
            </div>
          </div>
        </div>

        <video controls autoplay muted loop animate-fade>
          <source src="@/assets/threejs_vid.mp4" type="video/mp4">
        </video>
      </div>
    </div>

  </section>
</template>

<script>
import { ref, onMounted, onBeforeUnmount, reactive, computed, onBeforeMount } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Func from '@/helpers/Function';
import { useStore } from 'vuex';

export default {
  setup(props, {emit}) {
		const router = useRouter();
    const store = useStore();
    const userMode = computed(() => store.state.account.mode);
    
    onMounted(() => {
      Func.resetPage();
      Func.setupScrolltop();

      if(userMode.value == 'dark') {
        $(".site-research").addClass('darkmode');
      } else {
        $(".site-research").removeClass('darkmode');
      }

      $('[data-slick-fb]').slick({
        dots: false,
        infinite: false,
        prevArrow: $('[data-slick-btns] .slick-prev'),
        nextArrow: $('[data-slick-btns] .slick-next'),
      });
      //
      $('[animate-me]').each(function () {
        const c = new window.ScrollMagic.Controller();
        const t = gsap.from($(this), 1, { autoAlpha: 0, y: 50 });

        new window.ScrollMagic.Scene({
          triggerElement: this,
          offset: 0,
          reverse: false
        })
        .setTween(t)
        .addTo(c);
      });
      //
      $('[animate-fade]').each(function () {
        const c = new window.ScrollMagic.Controller();
        const t = gsap.from($(this), 1, { autoAlpha: 0 });

        new window.ScrollMagic.Scene({
          triggerElement: this,
          offset: 0,
          reverse: false
        })
        .setTween(t)
        .addTo(c);
      });
      //
      $('[animate-stagger]').each(function () {
        const c = new window.ScrollMagic.Controller();

        const t = window.TweenMax.staggerFrom($(this).find('[animate-item]'), 0.7, { autoAlpha: 0, y: 50 }, 0.3);

        new window.ScrollMagic.Scene({
          triggerElement: this,
          offset: -100,
          reverse: false
        })
        .setTween(t)
        .addTo(c);
      });
    });
  }
}
</script>

<style scoped>
</style>
